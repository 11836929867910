@import '../../styles/mixins';

.root {
  height: 40vh;
  max-height: 500px;
  background: url(../../assets/photos/oil.jpg) no-repeat 75% 100%;
  background-size: cover;

  @include for-tablet-landscape-up {
    height: 60vh;
  }
}
