@import './variables';

@mixin unstyledList {
  list-style: none;
  margin: 0;
  padding: 0;
}

@mixin for-phone-only {
  @media (max-width: $tabletPortraitBreakpoint - 1) { @content; }
}
@mixin for-tablet-portrait-up {
  @media (min-width: $tabletPortraitBreakpoint) { @content; }
}
@mixin for-tablet-landscape-up {
  @media (min-width: $tabletLandscapeBreakpoint) { @content; }
}
@mixin for-desktop-up {
  @media (min-width: $desktopBreakpoint) { @content; }
}
