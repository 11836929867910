@import '../../styles/mixins';

.root {
  @include unstyledList;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  border-bottom: 1px solid $colorLines;

  @include for-tablet-landscape-up {
    flex-direction: row;
    height: 80px;
    padding: 0;
  }
}

.item {
  margin: 5px 0;

  @include for-tablet-landscape-up {
    margin: 0 20px;
  }
}

.link {
  display: block;
  padding: 2px;
  border-bottom: 3px solid transparent;
  letter-spacing: 0.05em;
  font-family: $fontAlt;
  text-transform: uppercase;
  color: $colorTextLight;
  transition: color 100ms;

  &:hover {
    color: $colorBrand;
  }

  &[aria-current] {
    color: $colorBrand;
    border-bottom-color: rgba($colorBrand, 0.75);
  }
}
