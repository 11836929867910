@import '../../styles/variables';
@import '../../styles/mixins';

.root {
  position: relative;
  margin-bottom: -4px;

  @include for-tablet-landscape-up {
    margin-bottom: -50px;
  }
}

.logo {
  position: relative;
  display: block;
  width: 100%;
  padding: 20px;
  border-bottom: 4px solid $colorBrand;
  background: #fff;
  transition: transform 100ms;

  img {
    display: block;
    max-width: 300px;
    margin: 0 auto;
  }

  @include for-tablet-landscape-up {
    max-width: 400px;
    margin: 0 auto;
    padding: 48px 0 36px;

    &:hover {
      transform: translateY(-2px);
    }
  }
}

.emergency {
  $color: #c02b2e;

  position: absolute;
  top: 117px;
  right: 10px;
  display: flex;
  flex-direction: column;
  padding: 4px 8px 3px;
  border: 0.2em solid #fff;
  border-radius: 4px;
  background: $color;
  text-align: center;
  font-size: 12px;
  line-height: 1;
  color: #fff;
  transform: translateY(-50%);
  transition: all 100ms;

  &:hover {
    border-color: $color;
    background: #fff;
    color: $color;
  }

  @include for-tablet-landscape-up {
    top: calc(50% - 25px);
    right: 10px;
    border-color: $color;
    background: #fff;
    font-size: 14px;
    color: $color;

    &:hover {
      background: $color;
      color: #fff;
    }
  }
}

.emergencyPhone {
  margin-top: 0.1em;
  font-size: 1.575em;
  font-family: $fontAlt;
}
