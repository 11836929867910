$widthMax: 1200px;

$tabletPortraitBreakpoint: 600px;
$tabletLandscapeBreakpoint: 900px;
$desktopBreakpoint: 1200px;

$font: 'Helvetica Neue', Helvetica, Arial, sans-serif;
$fontAlt: 'proxima-nova', $font;

$colorBrand: #e9a12e;
$colorBackground: #e6ebeb;
$colorLines: $colorBackground;
$colorText: #333;
$colorTextLight: #6f7170;
