@import './styles/variables';

.root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  max-width: $widthMax;
  margin: 0 auto;
  background: #fff;
}

.body {
  flex: 1;
}
