@import '../../../styles/variables';
@import '../../../styles/mixins';

.tagline {
  margin: 0;
  font-family: $fontAlt;
  font-size: 28px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.1em;

  span {
    color: $colorBrand;
  }
}

.description {
  font-size: 18px;
  line-height: 1.4;

  p {
    margin: 0.75em 0;

    &:first-child {
      margin-top: 0;
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}

.services {
  @include unstyledList;
  margin-top: 40px;
}

.service {
  display: flex;
  align-items: flex-start;
  padding: 25px 0;
  border-top: 1px solid $colorBackground;

  &:last-child {
    border-bottom: 1px solid $colorBackground;
  }

  @include for-tablet-landscape-up {
    align-items: center;
  }
}

.servicePhoto {
  display: none;
  border-radius: 4px;
  margin-right: 25px;

  @include for-tablet-portrait-up {
    display: block;
    width: 33%;
  }

  @include for-tablet-landscape-up {
    width: 20%;
  }
}

.serviceText {
  flex: 1;
  line-height: 1.5;
}

.serviceTitle {
  margin: 0;
  font-size: 1.25em;
  font-family: $fontAlt;
  text-transform: uppercase;
  letter-spacing: 0.1em;
}

.serviceDescription {
  margin: 0.75em 0 0;
}
