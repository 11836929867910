@import '../../styles/variables';

.root {
  background: $colorBackground;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 250px;
  margin-bottom: 25px;
}

.copyright {
  font-size: 12px;
  color: $colorTextLight;
}
