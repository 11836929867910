@import '../../../styles/mixins';

.root {
  flex: 1;
  padding: 25px 0;

  &:not(:first-child) {
    border-top: 1px solid $colorBackground;
  }

  @include for-tablet-landscape-up {
    padding: 10px 40px;

    &:nth-child(2n) {
      border-top: 0;
      border-left: 1px solid $colorBackground;
    }
  }
}

.title {
  margin: 0;
  font-size: 22px;
}

.titleUnderlined {
  text-decoration: underline;
}

.subtitle {
  margin: 0.5em 0;
  font-size: 18px;
}

.content {
  margin-top: 1em;
}
