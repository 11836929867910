@import '../../styles/variables';
@import '../../styles/mixins';

.root {
  @include unstyledList;
}

.line {
  display: flex;
  margin: 0.75em 0;
}

.icon {
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin: 1px 10px 0 0;
  color: $colorBrand;
}

.text {
  //

  a {
    display: inline-block;
    border-bottom: 1px solid $colorBrand;
    transition: color 100ms;

    &:hover {
      color: $colorBrand;
    }
  }
}

.hours {
  @include unstyledList;
  font-size: 14px;
  padding-top: 0.2em;
}

.day {
  display: inline-block;
  width: 35px;
  font-style: italic;
}
