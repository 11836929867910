@import '../../../styles/mixins';

.root {
  @include unstyledList;
  display: flex;
  flex-direction: column;
  margin: -25px 0;

  @include for-tablet-landscape-up {
    flex-direction: row;
    margin: -10px -40px;
  }
}
