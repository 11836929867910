@import '../../../styles/variables';
@import '../../../styles/mixins';

.root {
  //
}

.container {
  display: flex;
  flex-direction: column;

  @include for-tablet-landscape-up {
    flex-direction: row;
  }
}

.details {
  margin-bottom: 25px;
  line-height: 1.4;

  @include for-tablet-landscape-up {
    width: 50%;
    margin-top: 0;
    margin-right: 25px;
  }
}

.title {
  margin: 0 0 1em;
  font-size: 24px;
  letter-spacing: 0.1em;
  font-family: $fontAlt;
  text-transform: uppercase;
}

.map {
  flex: 1;
  overflow: hidden;
  border-radius: 4px;
  background: #e5e3df;
}
